* {
  box-sizing: border-box; 
  background-color: white;
  font-family:sans-serif;
}
.app{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #146C94;
  font-size: 40px;
}
.title > :nth-child(2){
  color:#19A7CE;
  font-size: 20px;
}
.shortener {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3%;
  width: 60%;
  height: 120px;
  box-shadow: 2px 2px 4px 2px rgba(20, 108,	148, 0.3);
  padding: 20px;
  background-color: whitesmoke;
}
.shortener input, button {
  height: 30px;
  padding: 0 10px;
}
.shortener div > button {
  cursor:pointer;
  width: 15%;
  border-radius: 2px;
}
.shortener div > input {
  border: 1px solid #AFD3E2;
  color: #146C94;
  width: 85%;
  border-radius: 2px;
}
.shortener input:focus {
  border: 1px solid #19A7CE;
  outline: none;
}
.shortener div > button {
  background-color: #19A7CE;
  border: none;
  color: white;
}
::placeholder { 
  color: #AFD3E2;
  opacity: 1; 
}
.shortener div > button:hover {
  background-color: #146C94;
}
.result {
  margin-top: 15px;
  height: 120px;
  width: 60%;
  font-size: 70%;
  font-weight: bolder;
  color: grey;
}
.result >  a {
  color:#146C94;
  text-decoration: none;
}

/* Responsive style for mobile & ipad */
@media only screen and (max-width: 480px) {
  .shortener {
    width: 80%;
    height: 150px;
  }
  .title{
    font-size: 22px;
  }
  .title > :nth-child(2){
    font-size: 12px;  
  }
  .shortener div > input {
    width: 100%;
  }
  .shortener div > button {
    width: 100%;
  }
  .result {
    margin-top: 15px;
    height: 100px;
    width: 80%;
    font-size: 40%;
  }

}
@media only screen and (min-width: 481px) and (max-width: 900px) {
  .shortener {
    width: 80%;
  }
  .title{
    font-size: 25px;
  }
  .title > :nth-child(2){
    font-size: 15px;  
  }
  .shortener div > input {
    width: 75%;
  }
  .shortener div > button {
    width: 25%;
  }
  .result {
    margin-top: 15px;
    height: 100px;
    width: 80%;
    font-size: 50%;
  }
}